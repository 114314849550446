const switcher = document.querySelector('.js-languageSwitcher');
let iconbarTrigger = document.querySelector('.iconbar__trigger');
// let iconbarButton = document.querySelector('.iconbar__appear');
let sidemenuTrigger = document.querySelector('.sidemenu__trigger');
// let sidemenuButton = document.querySelector('.sidemenu__appear');
let menuToggle = document.querySelector('.sidemenu__navigation');

if (switcher) {
    switcher.addEventListener('change', function () {
        this.form.submit();
    });
}

// Show the submenu of the developers item, normal sidebar and iconbar
var devCollapse = document.querySelector('.sidemenu__submenu-parent');
var devIconbarCollapse = document.querySelector('.iconbar__submenu');
if (devCollapse) {
    devCollapse.addEventListener('click', function () {
        document.querySelector('.sidemenu__includes-submenu').classList.toggle('show-children');
        document.querySelector('.sidemenu__submenu-parent').classList.toggle('rotate');
    });
}
if (devIconbarCollapse) {
    devIconbarCollapse.addEventListener('click', function () {
        devIconbarCollapse.classList.toggle('show-children');
    });
}
