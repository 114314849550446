import './polyfills';
import 'bem-toolkit';
import './sidemenu.js';

//var dummy = gettext('Hi there.'); // See http://stackoverflow.com/questions/20802624/unable-to-generate-django-javascript-i18n-catalog
function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie != '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = jQuery.trim(cookies[i]);
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) == name + '=') {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function sorter(results) {
    return results.sort((a, b) => {
        return b.score - a.score;
    });
}

window.toggleIconBar = function (element, force) {
    const blockName = element.getBEMBlockName();
    const blockElement = document.querySelector(`.${blockName}`);

    blockElement.toggleBEMModifier('iconbar');

    const showIconBar = blockElement.hasBEMModifier('iconbar');
    const contentEl = document.querySelector('.dashboard__content') || document.querySelector('.frontendContainer');
    contentEl.toggleBEMModifier('iconbar', showIconBar);

    if (showIconBar) document.cookie = 'hideSideBar=true;path=/';
    // add cookie
    else document.cookie = 'hideSideBar=true;path=/;max-age=0'; // delete cookie
};

window.handleEventsMenuClick = function (element, url) {
    const alwaysShowSubmenu = window.innerWidth > 768 && window.innerHeight > 900;
    if (alwaysShowSubmenu) window.location.href = url;
    else element.toggleBEMModifier('submenu-open');
};
function matchAndscoreResults(params, data) {
    // Select2 matcher that also scores the results

    // If there are no search terms, return all of the data
    if ($.trim(params.term) === '') {
        return data;
    }

    // Do not display the item if there is no 'text' property
    if (typeof data.text === 'undefined') {
        return null;
    }

    // `params.term` should be the term that is used for searching
    // `data.text` is the text that is displayed for the data object
    if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
        let score = 1;
        if (data.text.toLowerCase().startsWith(params.term.toLowerCase())) score = score + 50;
        data.score = score;

        var modifiedData = $.extend({}, data, true);
        // You can return modified objects from here
        // This includes matching the `children` how you want in nested data sets
        return modifiedData;
    }

    // Return `null` if the term should not be displayed
    return null;
}

$(document).ajaxSend(function (event, xhr, settings) {
    function sameOrigin(url) {
        // url could be relative or scheme relative or absolute
        var host = document.location.host; // host + port
        var protocol = document.location.protocol;
        var sr_origin = '//' + host;
        var origin = protocol + sr_origin;
        // Allow absolute or scheme relative URLs to same origin
        return (
            url == origin ||
            url.slice(0, origin.length + 1) == origin + '/' ||
            url == sr_origin ||
            url.slice(0, sr_origin.length + 1) == sr_origin + '/' ||
            // or any other URL that isn't scheme relative or absolute i.e relative.
            !/^(\/\/|http:|https:).*/.test(url)
        );
    }
    function safeMethod(method) {
        return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
    }

    if (!safeMethod(settings.type) && sameOrigin(settings.url)) {
        xhr.setRequestHeader('X-CSRFToken', getCookie('csrftoken'));
    }
});

// Default functionality for Procss template
$(document).ready(function () {
    $('form.form__form--signup')
        .find('input, select')
        .not('[type="radio"]')
        .on('focus', function () {
            mainFormFieldFocus($(this));
        });
    $('form.form__form--signup')
        .find('input, select')
        .not('[type="radio"]')
        .on('blur', function () {
            mainFormFieldBlur($(this));
        });
    $('form.form__form--signup')
        .find('input, select')
        .not('[type="radio"]')
        .on('change', function () {
            mainFormFieldChange($(this));
        });
    $('form.form__form--signup')
        .find('input, select')
        .not('[type="radio"]')
        .each(function () {
            mainFormFieldChange($(this));
        });

    $('label > input[type="radio"]').parent('label').addClass('radioWrap');
    $('label > input[type="checkbox"]').parent('label').addClass('checkboxWrap');

    /*
     *  Required functionality:
     *  - When you click twice within 200ms on a button or a link, the second click SHOULD be prevented.
     *  - When the element you clicked has an allowDoubleClick, the second click SHOULD NOT be prevented.
     */
    $('a').click(function (ev) {
        if ($(this).hasClass('js-allowDoubleClick')) {
            return;
        }

        if ($(this).data('clicked') === true) {
            ev.preventDefault();
        } else {
            $(this).data('clicked', true);
            var _this = $(this);
            setTimeout(function () {
                _this.data('clicked', false);
            }, 1000);
        }
    });

    $(function () {
        $('[data-toggle="tooltip"]').tooltip();
    });

    $('form.js-submitOnce').submit(function (e) {
        if ($(this).hasClass('form-submitted')) {
            e.preventDefault();
            return;
        }
        $(this).addClass('form-submitted');
    });

    $('.js-confirm-delete-popup').click(function (event) {
        var form = $(
            '<button class="form-group__button--cancel delete-popover-cancel">Cancel</button><form method="post"><button class="button--secondary confirm-delete">Delete</button></form>'
        );
        var delEvent = $(
            '<form method="post"><button class="button--secondary confirm-delete">Confirm</button></form>'
        );
        var csrf = $('<input type="hidden" name="csrfmiddlewaretoken"></input>');
        csrf.val(getCookie('csrftoken'));
        form.attr('action', event.currentTarget.href);
        delEvent.attr('action', event.currentTarget.href);
        form.append(csrf);
        delEvent.append(csrf);
        window.preventLeavePageWarning = true;

        $(event.currentTarget)
            .popover({
                container: 'body',
                content: delEvent,
                html: true,
                placement: 'top',
                trigger: 'focus',
            })
            .popover('show');

        event.preventDefault();
    });

    $('.delete-popover-cancel').click(function () {
        window.preventLeavePageWarning = false;
        $('.popover').style('display: hidden;');
    });

    /*
    $('input[type="submit"], button[type="submit"]').click(function(ev) {
        $(this).attr('disabled', true);
    });
    */
    // Remove JS class from body when Javascript is enabled
    $('body').removeClass('js-isDisabled');

    $('select.select2').select2({
        matcher: matchAndscoreResults,
        sorter: sorter,
    });

    $('.js-triggerSideMenu').click(function () {
        if ($('.js-sideMenu').hasClass('is-active')) {
            $('.js-sideMenu').removeClass('is-active');
            $('body').removeClass('overflowHidden');
        } else {
            $('.js-sideMenu').addClass('is-active');
            $('body').addClass('overflowHidden');
        }
    });

    $('body').click(function (evt) {
        if ($(evt.target).hasClass('overflowHidden')) {
            $('.js-sideMenu').removeClass('is-active');
            $('body').removeClass('overflowHidden');
        }
    });

    $('.js-hideMessages').click(function () {
        $('.js-messages').hide();
    });

    $('.js-messages').on('animationend', function (event) {
        if (event.originalEvent.animationName == 'fadeOut') {
            $('.js-messages').hide();
        }
    });

    $('.js-openSubMenu').click(function () {
        if ($(this).hasClass('is--active')) {
            $(this).removeClass('is--active');
        } else {
            $(this).addClass('is--active');
        }
    });

    $('.js-openSubMenu .is--submenu__child').click(function (event) {
        event.stopPropagation();
    });

    initClearableFileFieldWidgets();
    initTabs();
    initFilters();
});

function initClearableFileFieldWidgets() {
    $('.jsClearFile').click(function () {
        $('.jsClearableFile').remove();
        var url = $('input[name="delete-files"]').data('delete-' + $(this).data('field-name'));
        $.ajax({
            url: url,
            method: 'POST',
            // IE10 fails to read csrf token from cookies
            headers: { 'X-CSRFToken': $('input[name="csrfmiddlewaretoken"]').val() },
            success: function (data) {
                alert('File deleted');
            },
            error: function () {
                alert('Failed to delete file');
            },
        });
    });
}

function initTabs() {
    $('.tabs .tablink').click(function () {
        $(this).addClass('active').siblings().removeClass('active');
        $('.' + $(this).data('target-class'))
            .show()
            .siblings()
            .hide();
        eval($(this).data('callback'));
    });
}

function initFilters() {
    $('.filters .filterlink').click(function () {
        $(this).addClass('active').siblings().removeClass('active');
        $('.' + $(this).data('target-class'))
            .show()
            .siblings()
            .hide();
        eval($(this).data('callback'));
    });
}

/*
Open confirmation modal
*/
window.customConfirm = function (modal_title, modal_message, callback) {
    $('.js-confirmModal')
        .find('.js-confirmModalConfirm')
        .unbind('click')
        .click(function () {
            callback();
            $('.js-confirmModal').hide();
        });
    $('.js-confirmModal')
        .find('.js-confirmModalCancel')
        .unbind('click')
        .click(function () {
            $('.js-confirmModal').hide();
        });
    $('.js-confirmModal').find('.js-confirmModalTitle').text(modal_title);
    $('.js-confirmModal').find('.js-confirmModalMessage').text(modal_message);
    $('.js-confirmModal').show();
};

window.customInformation = function (opt_modal) {
    $(opt_modal)
        .find('.close')
        .click(function () {
            $(opt_modal).hide();
        });
    $(opt_modal)
        .find('.fa-check')
        .click(function () {
            $(opt_modal).hide();
        });
    $(opt_modal).click(function () {
        $(opt_modal).hide();
    });
    $('.sendEmailModal-content').click(function (event) {
        event.stopPropagation();
    });
    $(opt_modal).show();
};

window.customAlert = function (modal_title, modal_message) {
    $('.js-alertModal')
        .find('.js-alertModalCancel')
        .unbind('click')
        .click(function () {
            $('.js-alertModal').hide();
        });
    $('.js-alertModal').find('.js-alertModalTitle').text(modal_title);
    $('.js-alertModal').find('.js-alertModalMessage').text(modal_message);
    $('.js-alertModal').show();
};

/*
Control login and registration forms
*/
function mainFormFieldFocus(field) {
    field.parents('.form__form-group').addClass('is-focus');
}

function mainFormFieldBlur(field) {
    if (field.val() == '') {
        field.parents('.form__form-group').removeClass('is-focus');
    }
}

function mainFormFieldChange(field) {
    if (field.is('select')) {
        if (
            field.find('option:selected').data('name') &&
            field.find('option:selected').data('name').indexOf('---') == -1
        ) {
            field.parents('.form__form-group').addClass('is-focus');
            field.siblings('.form__select-placeholder').hide();
            field.siblings('.form__select-value').text(field.find('option:selected').data('name'));
        } else {
            field.siblings('.form__select-placeholder').show();
            field.siblings('.form__select-value').text('');
        }
    }
    if (field.is('input')) {
        if (field.val() != '') {
            field.parents('.form__form-group').addClass('is-focus');
        }
    }
}

$('.js-followRow').click(function (event) {
    document.location = event.target.closest('tr').dataset['rowTarget'];
});
const bodyContainer = document.querySelector('body');
const frontendContainer = document.querySelector('.frontendContainer');

window.triggerMenu = function () {
    const menuElement = document.querySelector('.js-primary-menu');
    menuElement.addBEMModifier('is-visible');
};

document.querySelector('body').addEventListener('click', function (event) {
    if (event.target.classList.contains('sidemenu-isOpen')) {
        frontendContainer.classList.remove('sidemenu-isOpen');
        bodyContainer.removeAttribute('style');
    }
});

window.openNewFeatures = function () {
    document.querySelector('.primary-dashboard-menu__wrapper').removeBEMModifier('is-visible');
    document.querySelector('.new-features').addBEMModifier('is-open');
};

window.closeNewFeatures = function () {
    document.querySelector('.new-features').removeBEMModifier('is-open');
};
